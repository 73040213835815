<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>社区管理</el-breadcrumb-item>
            <el-breadcrumb-item>审核帖子</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            选择板块：
            <el-select v-model="select" placeholder="请选择一级板块" @change="change">
                <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="select2" placeholder="请选择二级板块" @change="change2" style="margin-right: 20px;">
                <el-option v-for="item in options2" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" style="margin-left:20px;margin-right: 0;" @click="query">查询</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column label="作者姓名" prop="author_name"></el-table-column>
            <el-table-column label="内容" prop="content"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                        <a @click="lookup(scope.row)">查看</a>
                        <a @click="shenhe(scope.row)">审核</a>
                        <a @click="setjh(scope.row)">设为精华</a>
                        <a @click="del(scope.row)">删除</a>
                        <a @click="reply(scope.row)">回复</a>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                options: [],
                select: '',
                options2: [],
                select2: '',
            }
        },
        mounted() {
            this.getbk();
        },
        methods: {
            getbk() {
                this.axios.get('/bbs/get_gu_bbs_forum?forum_id=' + 0)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options = res.data.result;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            change(value) {
                this.options2 = [];
                this.select2 = '';
                this.axios.get('/bbs/get_gu_bbs_forum?forum_id=' + value)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options2 = res.data.result;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            change2(value) { },
            getdata(page, value) {
                this.axios.get("/bbs/get_gu_bbs_post?page=" + page + "&size=" + this.PageSize + "&forum_id=" + value + "&audit=0")
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage, this.select2);
            },
            query() {
                this.totalCount = 0;
                this.list = [];
                this.getdata(1, this.select2);

            },
            lookup(row) {
                
            },
            shenhe(row){
                if (row.audit == 0) {
                    row.audit = 1;
                } else {
                    row.audit = 0;
                }
                this.setsome("/bbs/update_gu_bbs_post_audit", { audit: row.audit, id: row.id }, 'audit');
            },
            setjh(row){
                if (row.di == 0) {
                    row.di = 1;
                } else {
                    row.di = 0;
                }
                this.setsome("/bbs/update_gu_bbs_post_di", { di: row.di, id: row.id }, 'di');
            },
            setsome(url, form, caozuo) {
                this.axios.post(url, this.qs.stringify(form))
                    .then((res) => {
                        if (res.data.code == 0) {
                            if (caozuo == 'di') {
                                if (this.di != 0) {
                                    this.btntext = '取消精华';
                                    this.$message({
                                        type: 'success',
                                        message: '设置成功!'
                                    });
                                } else {
                                    this.btntext = '设为精华';
                                    this.$message({
                                        type: 'success',
                                        message: '取消成功!'
                                    });
                                }
                            } else {
                                if (this.audit != 0) {
                                    this.auditbtn = '取消审核';
                                    this.$message({
                                        type: 'success',
                                        message: '审核成功!'
                                    });
                                } else {
                                    this.auditbtn = '审核';
                                    this.$message({
                                        type: 'success',
                                        message: '取消审核成功!'
                                    });
                                }
                            }
                        } else {
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            del(row,index){
                this.$confirm('此操作将永久删除该帖子, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/bbs/delete_gu_bbs_post", this.qs.stringify({ id: row.id }))
                        .then((res) => {
                            if (res.data.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                                this.list.splice(index,1);
                            } else {
                                this.$message(res.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .hdtc {
        width: 80%;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add .el-button {
        margin-right: 20px;
    }
</style>